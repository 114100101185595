import React from "react"

// Components
import Layout from "../components/layout/layout"
import SEO from "../components/helmet/seo"
import Home from "../components/page-partials/home"

// Markup
const IndexPage = () => {
  return (
    <Layout mainColor="#000814" styleClass="footer-light disabled-btn-menu">
      <SEO title="Hello!"/>
      <Home />
    </Layout>
  )
}
export default IndexPage
