import React from "react"

// Components
import AvatarSitSVG from "../partials/svg/avatarSit"
import NavLink from "../layout/nav"

// Markup
const Home = () => {
  return (
    <>
      <div id="home" className="container">
          <section className="main-page-title noselect">
            <h5 className="title-username typing-effect">Martin Jean-Christio</h5>
            <h1>Software<span><br/>Developer</span></h1>
            <h5 className="title-username--mobile typing-effect">Martin Jean-Christio</h5>
            <h5 className="title-location">
              <a href="https://www.google.com/maps/place/Valais/data=!4m2!3m1!1s0x478f229bea7f9a17:0x100ff884018d860?sa=X&ved=2ahUKEwjatNft3dL0AhVN-aQKHcBuCF0Q8gF6BAgSEAE">
                <span>Valais, Suisse</span> 🇨🇭
              </a>
            </h5>
          </section>
          <section className="main-page-img">
            <NavLink 
              url="about" 
              styleClass="nav-link nav-link--about" 
              title="A propos" 
              description="Whoami?"/>
            <NavLink 
              url="portfolio" 
              styleClass="nav-link nav-link--portfolio" 
              title="Mes projets" 
              description="Jettes-y un coup d'œil!"/>
            <NavLink 
              url="contact" 
              styleClass="nav-link nav-link--contact" 
              title="Me contacter" 
              description="Dis-moi?"/>
            <AvatarSitSVG styleClass="svg-avatar"/>
          </section>
      </div>
    </>
  )
}
export default Home
