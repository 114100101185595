import React from "react"

// Markup

const ArrowThree = () => {
  return (
    <svg className="arrow arrow-three" width="136" height="49" viewBox="0 0 136 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M72.3634 41.0675C64.8474 46.2505 57.1994 40.1855 59.0734 31.7475C60.7384 24.2555 67.4874 20.9445 74.4274 19.7405C78.2514 26.6155 79.4324 36.1925 72.3634 41.0675ZM1.18537 18.0855C1.41537 18.2425 1.72437 17.9295 1.57037 17.7005C0.269369 15.7615 13.9504 10.8965 15.7674 10.1775C19.6114 8.65751 23.5234 7.29351 27.5234 6.24351C35.8664 4.05251 44.6454 3.24351 53.1144 5.21751C59.6194 6.73551 65.9204 9.81051 70.5984 14.6415C64.2614 16.2445 58.3574 19.6595 55.3554 25.7185C52.6034 31.2745 52.2024 38.7305 56.3334 43.7405C60.7454 49.0895 68.4454 49.4425 74.1824 46.0545C80.8854 42.0965 83.2654 34.0505 82.0314 26.6735C81.5804 23.9795 80.7384 21.4785 79.5924 19.1705C95.6894 17.9815 114.33 20.2805 128.126 28.5675C128.223 28.6265 128.32 28.6595 128.416 28.7055C126.233 29.8415 124.091 31.0825 122.435 32.7855C120.941 34.3205 122.436 36.9605 124.532 36.3885C128.252 35.3775 131.522 32.6695 134.769 30.6375C136.052 29.8365 136.3 28.0885 135.24 26.9875C130.437 21.9975 125.789 16.7965 120.585 12.2225C119.427 11.2055 117.272 12.5705 118.303 13.9835C119.697 15.8955 121.171 17.7475 122.682 19.5705C116.465 17.0415 109.886 15.5265 103.151 14.6425C94.3444 13.4885 84.9344 12.6645 76.0284 13.7035C69.2404 5.50251 58.0544 0.802513 47.5034 0.098513C36.7774 -0.617487 26.0414 2.68451 16.3064 6.88151C14.4274 7.69251 -3.52863 14.8595 1.18537 18.0855Z"/>
    </svg>
  )
}


export default ArrowThree
