import { Link } from "gatsby"
import React from "react"
import ArrowOne from "../partials/svg/arrowOne"
import ArrowThree from "../partials/svg/arrowThree"
import ArrowTwo from "../partials/svg/arrowTwo"

// Components

// Markup
const NavLink = ({ url, styleClass, title, description }) => {
  return (
    <Link to={url} className={`${styleClass}`}>
        <div>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
        <ArrowOne/>
        <ArrowTwo />
        <ArrowThree />
    </Link>
  )
}

export default NavLink