import React from "react"

// Markup
const ArrowOne = () => {
  return (
    <svg className="arrow arrow-one" width="118" height="66" viewBox="0 0 118 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.7008 65.1386C22.8558 65.9436 24.2778 62.9366 22.8448 61.4566C22.0738 60.6616 21.2048 59.9646 20.2728 59.3366C26.8208 58.4886 33.6448 56.1296 39.0418 54.0886C48.1238 50.6526 56.6068 45.8596 64.1848 39.7846C80.2008 26.9436 93.2938 0.0796161 117.286 2.75562C117.556 2.78562 117.59 2.36962 117.347 2.29462C95.9718 -4.32838 81.5248 15.6766 68.2638 28.6716C60.5468 36.2346 51.5398 42.3716 41.5918 46.6146C36.7448 48.6816 31.6998 50.2906 26.5568 51.4426C21.3448 52.6096 15.6848 52.3926 10.6248 53.9576C9.87784 54.1896 9.32984 54.7266 9.00984 55.3726C8.55284 55.3066 8.09684 55.2486 7.64084 55.1996C10.7358 52.5886 14.0158 50.2136 17.3268 47.8696C17.6698 47.6276 17.4678 47.0906 17.0378 47.1856C10.9388 48.5266 5.71184 51.6096 0.896842 55.5686C-0.653158 56.8416 0.746842 59.0736 2.39984 59.1966C5.75184 59.4446 9.04184 60.1386 12.2138 61.2526C15.1798 62.2946 17.7678 64.0446 20.7008 65.1386Z"/>
    </svg>
  )
}

export default ArrowOne
